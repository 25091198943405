export const loadState = version => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    const {state, version: storedVersion} = JSON.parse(serializedState);
    if (version !== storedVersion) {
      return undefined;
    }
    return state;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state, version) => {
  try {
    // Do not store anything while there are pending actions.
    if (state.poll.pendingFiles.length > 0 || state.poll.isSubmitting || state.poll.formErrors) {
      return;
    }

    const serializedState = JSON.stringify({state, version});
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};
