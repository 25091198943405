import React from 'react';
import {Provider} from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import ThemeTopLayout from 'gatsby-theme-material-ui-top-layout/src/components/top-layout';

import featureSaga from "../../features/sagas";
import createStore from '../../state/createStore';

export default function TopLayout({children, theme}) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(sagaMiddleware);

  sagaMiddleware.run(featureSaga);

  return (
    <Provider store={store}>
      <ThemeTopLayout theme={theme}>{children}</ThemeTopLayout>
    </Provider>
  );
}
