import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {combineReducers} from "redux";
import featureReducers from "../features/reducers";
import {loadState, saveState} from "./localStorage";
import throttle from 'lodash.throttle';

const devMode = (process.env.NODE_ENV !== 'production');

const VERSION = 1;

const createStore = sagaMiddleware => {
  const store = configureStore({
    reducer: combineReducers({
      ...featureReducers,
    }),
    middleware: [
      ...getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          ignoredActions: ['poll/uploadFile'],
        },
      }),
      sagaMiddleware,
    ],
    devTools: devMode,
    preloadedState: loadState(VERSION),
  });

  store.subscribe(throttle(() => {
    saveState(store.getState(), VERSION);
  }, 1000, {
    trailing: true,
  }));

  return store;
}

export default createStore;
