import {call, delay, fork, put, race, select, take, takeEvery} from 'redux-saga/effects';
import {
  deletedFile,
  deleteFile,
  getAuthToken,
  getUploadedFile,
  getUploadedFileUUIDs,
  hasUploadsInProgress,
  submit,
  submitFailed,
  submitSuccessful,
  updateFile,
  uploadedFile,
  uploadFailed,
  uploadFile,
} from './poll-slice';
import axios from 'axios';

function* watchUploadFile(action) {
  const {internalId, name, size, blob} = action.payload;

  let data = new FormData();
  data.append('name', name);
  data.append('file', blob);

  for (let i = 0; i < 4; i++) {
    try {
      const response = yield call(axios.post, '/api/files/', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 201) {
        yield put(uploadedFile({internalId, name, size, ...response.data}));
        return;
      } else {
        yield delay(i * 1000);
      }
    } catch (e) {
      console.error(e);
      yield delay(i * 1000);
    }
  }

  yield put(uploadFailed({internalId}));
}

function* watchDeleteFile(action) {
  const {internalId, ignoreErrors} = action.payload;

  const fileData = (yield select(getUploadedFile))(internalId);

  try {
    yield call(axios.delete, `/api/files/${fileData.uuid}/`, {
      params: {
        auth_token: (yield select(getAuthToken))(internalId),
      },
    });
    yield put(deletedFile({internalId}));
  } catch (e) {
    if (ignoreErrors) {
      yield put(deletedFile({internalId}));
    } else {
      yield put(updateFile({internalId, ...fileData, deletionRequested: false}));
    }
  }
}

function* handleSubmission() {
  while (true) {
    const action = yield take([submit]);
    const {data} = action.payload;

    try {
      // Wait for file uploads...
      while (true) {
        if (!(yield select(hasUploadsInProgress))) {
          break;
        }

        yield race({
          timeout: delay(1000),
          fileUpload: take([uploadedFile]),
        });
      }

      const files = yield select(getUploadedFileUUIDs);

      const formData = {
        ...data,
        files,
      };

      const response = yield call(axios.post, '/api/polls/', formData);
      if (response.data) {
        yield put(submitSuccessful(response.data));
      } else {
        throw new Error("submit failed");
      }

      yield put(submitSuccessful(response.data));
    } catch (e) {
      console.error(e);
      yield put(submitFailed({errors: e?.response?.data || {global: "Ein unerwarteter Fehler ist aufgetreten."}}));
    }
  }
}

export default function* rootSaga() {
  yield takeEvery(uploadFile, watchUploadFile);
  yield takeEvery(deleteFile, watchDeleteFile);

  yield fork(handleSubmission);
}
