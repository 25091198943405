import {colors, createMuiTheme} from '@material-ui/core';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: `#556cd6`,
    },
    secondary: {
      main: `#19857b`,
    },
    error: {
      main: colors.red.A400,
    },
    background: {
      default: `#fff`,
    },
  },
  overrides: {
    MuiAlert: {
      standardInfo: {
        backgroundColor: '#fafafa',
        color: 'black',
      },
      message: {
        width: '100%',
      }
    },
  }
});

export default theme;
