import {createAction, createSelector, createSlice} from "@reduxjs/toolkit";

const initialState = {
  authTokenByFileId: {},
  filesById: {},
  pendingFiles: [],
  uploadedFiles: [],
  formData: {},
  formErrors: undefined,
  selectedPoll: undefined,
};

const pollSlice = createSlice({
  name: 'poll',
  initialState,
  reducers: {
    uploadFile(state, action) {
      const {internalId, name, size} = action.payload;
      state.filesById[internalId] = {name, size};
      state.pendingFiles.push(internalId);
      state.formErrors = undefined;
    },
    uploadedFile(state, action) {
      const {internalId, auth_token, ...other} = action.payload;
      state.filesById[internalId] = other;
      state.uploadedFiles.push(internalId);
      state.pendingFiles = state.pendingFiles.filter(id => (id !== internalId));
      state.authTokenByFileId[internalId] = auth_token;
    },
    uploadFailed(state, action) {
      const {internalId} = action.payload;
      state.filesById[internalId] = {
        ...state.filesById[internalId],
        uploadFailed: true,
      };
    },
    updateFile(state, action) {
      const {internalId, ...other} = action.payload;
      state.filesById[internalId] = {
        ...state.filesById[internalId],
        ...other,
      };
    },
    deleteFile(state, action) {
      const {internalId} = action.payload;
      state.filesById[internalId] = {
        ...state.filesById[internalId],
        deletionRequested: true,
      };
      state.formErrors = undefined;
    },
    deletedFile(state, action) {
      const {internalId} = action.payload;
      if (state.filesById[internalId]) {
        delete state.filesById[internalId];
      }
      if (state.authTokenByFileId[internalId]) {
        delete state.authTokenByFileId[internalId];
      }
      if (state.uploadedFiles.includes(internalId)) {
        state.uploadedFiles = state.uploadedFiles.filter(id => (id !== internalId));
      }
      if (state.pendingFiles.includes(internalId)) {
        state.pendingFiles = state.pendingFiles.filter(id => (id !== internalId));
      }
      state.formErrors = undefined;
    },
    storeFormData(state, action) {
      const {data} = action.payload;
      state.formData = data;
      state.formErrors = undefined;
    },
    reset(state, action) {
      return initialState;
    },
    submit(state, action) {
      state.isSubmitting = true;
    },
    submitSuccessful(state, action) {
      const {uuid} = action.payload;
      return {
        ...initialState,
        isSubmitting: undefined,
        selectedPoll: uuid,
      };
    },
    submitFailed(state, action) {
      const {errors} = action.payload;
      state.formErrors = errors;
      state.isSubmitting = false;
      if (errors?.files) {
        state.authTokenByFileId = {};
        state.filesById = {};
        state.pendingFiles = [];
        state.uploadedFiles = [];
      }
    },
  },
});

export const {uploadFile, uploadedFile, deleteFile, deletedFile, updateFile, uploadFailed, storeFormData, reset, submitSuccessful, submitFailed} = pollSlice.actions;
export const submit = createAction('poll/submit');

export default {
  poll: pollSlice.reducer,
};

const getPollSliceState = state => state.poll;

const getFilesById = createSelector(
  getPollSliceState,
  poll => poll.filesById,
);

const getAuthTokensById = createSelector(
  getPollSliceState,
  poll => poll.authTokenByFileId,
);

export const getPendingFileInternalIds = createSelector(
  getPollSliceState,
  poll => poll.pendingFiles,
);

export const getUploadedFileInternalIds = createSelector(
  getPollSliceState,
  poll => poll.uploadedFiles,
);

const defaultFile = {};

export const getPendingFile = createSelector(
  getPendingFileInternalIds,
  getFilesById,
  (pendingFileInternalIds, filesById) => id => {
    if (!pendingFileInternalIds.includes(id)) {
      return defaultFile;
    }
    return filesById[id] || defaultFile;
  },
);

export const getUploadedFile = createSelector(
  getUploadedFileInternalIds,
  getFilesById,
  (uploadedFileInternalIds, filesById) => id => {
    if (!uploadedFileInternalIds.includes(id)) {
      return defaultFile;
    }
    return filesById[id] || defaultFile;
  },
);

export const getUploadedFileUUIDs = createSelector(
  getUploadedFileInternalIds,
  getUploadedFile,
  (uploadedFileInternalIds, getUploadedFile) =>
    uploadedFileInternalIds.map(getUploadedFile).map(fileData => fileData.uuid).filter(uuid => uuid),
);

export const getAuthToken = createSelector(
  getAuthTokensById,
  (authTokensById) => id => {
    return authTokensById[id];
  },
);

export const getFormData = createSelector(
  getPollSliceState,
  poll => poll.formData,
);

export const getFormErrors = createSelector(
  getPollSliceState,
  poll => poll.formErrors,
);

export const hasData = createSelector(
  getFormData,
  getUploadedFileInternalIds,
  getPendingFileInternalIds,
  (formData, uploadedIds, pendingIds) =>
    (uploadedIds.length > 0 || pendingIds.length > 0 || Object.values(formData).filter(x => x).length > 0),
);

export const isSubmitting = createSelector(
  getPollSliceState,
  poll => poll.isSubmitting,
);

export const getSelectedPoll = createSelector(
  getPollSliceState,
  poll => poll.selectedPoll,
);

export const hasUploadsInProgress = createSelector(
  getPendingFileInternalIds,
  getPendingFile,
  (pendingIds, getPendingFile) =>
    pendingIds.map(getPendingFile).filter(data => !data?.uploadFailed).length > 0,
);
